import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { HTMLContent } from "../components/Content";
import CoreStoryPageTemplate from "../components/CoreStoryTemplate";
import Layout from "../components/Layout";

const CoreStoryPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.meta_title}</title>
        <meta name="description" content={post.frontmatter.meta_description} />
      </Helmet>
      <CoreStoryPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        paragraph_1 = {post.frontmatter.paragraph_1}
        paragraph_2 = {post.frontmatter.paragraph_2}
        paragraph_3 = {post.frontmatter.paragraph_3}
        paragraph_4 = {post.frontmatter.paragraph_4}
        paragraph_5 = {post.frontmatter.paragraph_5}
        paragraph_6 = {post.frontmatter.paragraph_6}
        read_more = {post.frontmatter.read_more}
        fact_1 = {post.frontmatter.fact_1}
        quote_1 = {post.frontmatter.quote_1}
        quote_author_1 = {post.frontmatter.quote_author_1}
        image_1 = {post.frontmatter.image_1}
        image_2 = {post.frontmatter.image_2}
        image_3 = {post.frontmatter.image_3}
        animation_image = {post.frontmatter.animation_image}
        medium_link = {post.frontmatter.medium_link}

        // description={post.frontmatter.description}
        content={post.html}
        offerings={post.frontmatter.offerings}
      />
    </Layout>
  );
};

CoreStoryPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CoreStoryPage;

export const corestoryPageQuery = graphql`
  query CoreStoryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        paragraph_1
        paragraph_2
        paragraph_3
        paragraph_4
        paragraph_5
        paragraph_6
        read_more
        quote_1
        quote_author_1
        fact_1
        image_1
        image_2
        image_3
        animation_image
        medium_link
        
        meta_title
        meta_description
        offerings {
          blurbs {
            quote
            image
            text
          }
        }
      }
    }
  }
`;
