import React from "react";
import Content from "../Content";
import PropTypes from "prop-types";
import "./index.css";
import { faAlignCenter } from "@fortawesome/free-solid-svg-icons";
import AOS from 'aos'
import 'aos/dist/aos.css';
function getWindowDimensions() {
  if (typeof windowGlobal === "undefined") {
    return 600;
  } else {
    const { innerWidth: width } = windowGlobal;
    return {
      width,
    };
  }
}

const StoryButtons = ({button}) => {
  var buttons = ["inactive", "inactive", "inactive"]
  if (button == "PPE for the Navajo Nation") {
    buttons[0] = "active-button";
  }
  else if (button == "PPE for Protestors") {
    buttons[1] = "active-button";
  }
  else {
    buttons[2] = "active-button";
  }
  return (
    <div className="core-navbar">
      <a href="/stories/navajonation" className={buttons[0]}>PPE 4 the Navajo Nation</a>
      <a href="/stories/blm" className={buttons[1]}>PPE 4 Protestors</a>
      <a href="/stories/homelessness" className={buttons[2]}>PPE 4 the Homeless</a>
    </div>
  )
}

if(typeof window !== 'undefined' || typeof document !== 'undefined'){
  if (window.location.pathname.indexOf('admin') > 0) {
    AOS.init({
      disable: false,
    });
  } else AOS.init({disable: false});
}


const windowGlobal = typeof window !== "undefined" && window;

const CoreStoryPageTemplate = ({
  title,
  // description,
  paragraph_1,
  paragraph_2,
  paragraph_3,
  paragraph_4,
  paragraph_5,
  paragraph_6,
  read_more,
  quote_1,
  quote_author_1,
  fact_1,
  image_1,
  image_2,
  image_3,
  animation_image,
  medium_link, 
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;
  const { width } = getWindowDimensions();

  // TEMPLATE FOR THE HOMELESSNESS PAGE
  function HomelessnessTemplate() {
    return (
      <>
      <div>
        <div className="hero-body">
          <div className="columns story-override">
            {/* <StoryButtons button={title}/>     */}
          </div>
        </div>
          {/* <div className="column is-10 is-offset-1"> */}
          {/* removing column is-10 will make the text and images go from to edge */}
          <div>
            <div className="homelessness-title-background">
              <br></br>
              <h1 className="title core-title" data-aos='fade-up' data-aos-duration='2000'>{title}</h1>
            </div>
            <br></br>
            <div className="fact-background-homelessness">
                <h2 className="fact core-fact">{fact_1}</h2>
            </div>
            <p className='paragraph core-text' >{paragraph_1}</p>
            <br></br>
            <img className='core-image'  src={image_1} data-aos='zoom-in' data-aos-duration='2000' data-aos-delay='500'/>
            <br></br>
            <p className='paragraph core-text1' >{paragraph_2}</p>
            <img src={image_2} className='core-image' data-aos='zoom-in' data-aos-duration='2000' data-aos-delay='500' />
            <br></br> 
            <p className='paragraph core-text2'  >{paragraph_3}</p>
            <p className='paragraph core-text2'>{paragraph_4}</p>
            <br></br>
            <img src={image_3} className='core-image' data-aos='fade-in' data-aos-duration='1000' data-aos-delay='500' />
            <br></br>
            <p className='paragraph core-text4'  data-aos='fade-in' data-aos-duration='1000' data-aos-delay='500' >{read_more}</p>
            <br></br>
            <div className="fact-background-homelessness">
              <h2 className="fact core-fact">
                <q>{quote_1}</q>
                <p>{quote_author_1}</p>
              </h2>
            </div>
            <div className="read-more">
              <form action={medium_link}>  
                <button>Read More</button>
              </form>
            </div>
            <br></br>
          </div>
        </div>
      <section className="section3"></section>
      </>
    )
  }

  // TEMPLATE FOR THE BLM PAGE
  function BLMTemplate() {
    return (
      <div>
      <div className="hero-body">
        <div className="columns story-override">
          {/* <StoryButtons button={title}/>   */}
        </div>
      </div>
        <div>
          {/* removing column is-10 will make the text and images go from to edge */}
          {/* <div className="column is-10 is-offset-1"> */}
          <div >
            <div className="blm-title-background">
            <br></br>
              <h1 data-aos='fade-up' data-aos-duration='2000' className="title core-title">{title}</h1>
            </div>
            <br></br>
            <div className="fact-background-blm">
              <h2 className="fact core-fact">{fact_1}</h2>
            </div>
            <p className='paragraph core-text'>{paragraph_1}</p>
            <br></br>
            <img src={image_1} className='core-image' data-aos='zoom-in' data-aos-duration='2000' data-aos-delay='500'/>
            <br></br>
            <p className='paragraph core-text1' >{paragraph_2}</p>
            {/* <div id="grad1"></div> */}
            {/* <br></br> */}
            <img src={image_2} className='core-image' data-aos='zoom-in' data-aos-duration='2000' data-aos-delay='500'/>
            {/* <div id="grad1"></div> */}
            {/* <br></br> */}
            <p className='paragraph core-text2' ><span>{paragraph_3}</span></p>
            <br></br>
            <img src={image_3} className='core-image' data-aos='zoom-in' data-aos-duration='2100' data-aos-delay='500'/>
            <br></br>
            <p className='paragraph core-text4' data-aos='fade-in' data-aos-duration='1000' data-aos-delay='500' >{read_more}</p>
            <br></br>
            <div className="fact-background-blm">
            <h2 className="fact core-fact">
              <q>{quote_1}</q>
              <p>{quote_author_1}</p>
            </h2>
            </div>
            <br></br>
          </div>
      </div>
      <section className="section3"></section>
    </div>
    )
  }

  // TEMPLATE FOR THE NAVAJO NATION PAGE
  function NavajoNationTemplate() {
    return (
      <div>
      <div className="hero-body">
        <div className="columns story-override">
          {/* <StoryButtons button={title}/>   */}
        </div>
      </div>
        <div>
          {/* <div className="column is-10 is-offset-1"> */}
            {/* removing column is-10 will make the text and images go from to edge */}
          <div>
            <div className="navajo-title-background">
              <br></br>
              <h1 className="title core-title" data-aos='fade-up' data-aos-duration='2000'>{title}</h1>
            </div>
            <br></br>
            <div className="fact-background-navajo">
              <h2 className="fact core-fact">{fact_1}</h2>
            </div>
            <p className='paragraph core-text'>{paragraph_1}</p>
            <br></br>
            <img src={image_1} className='core-image' data-aos='zoom-in' data-aos-duration='2000' data-aos-delay='500' />
            <br></br>
            <p className='paragraph core-text1'> {paragraph_2}</p>
            {/* <div id="grad1"></div> */}
            {/* <br></br> */}
            <img src={image_2} className='core-image' data-aos='zoom-in' data-aos-duration='2000' data-aos-delay='500'/>
            {/* <div id="grad1"></div> */}
            {/* <br></br> */}
            <p className='paragraph core-text2'>{paragraph_3}</p>
            {/* <br></br> */}
            <p className='paragraph core-text2'>{paragraph_4}</p>
            {/* <br></br> */}
            <p className='paragraph core-text2'>{paragraph_5}</p>
            {/* <br></br> */}
            <img src={image_3} className='core-image' data-aos='zoom-in' data-aos-duration='1000'/>
            <br></br>
            <p className="paragraph core-text4" data-aos='fade-in' data-aos-duration='1000' data-aos-delay='500'>{read_more}</p>
            <br></br>
            <div className="fact-background-navajo">
            <h2 className="fact core-fact">
              <q>{quote_1}</q>
              <p>{quote_author_1}</p>
              </h2>
            </div>
            <form action={medium_link} className="read-more">  
              <button>Read More</button>
            </form>
            <br></br>
          </div>
        </div>
      <section className="section3"></section>
    </div>
    )
  }

  // when you change a title, change the title here too!!
  return (
    <>
    {title === "PPE for the Navajo Nation" ? NavajoNationTemplate() : (
      title == "PPE for Protestors" ? BLMTemplate() : 
      HomelessnessTemplate())}
    </>
  );
};

CoreStoryPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  paragraph_1: PropTypes.string,
  paragraph_2: PropTypes.string,
  paragraph_3: PropTypes.string,
  paragraph_4: PropTypes.string,
  paragraph_5: PropTypes.string,
  paragraph_6: PropTypes.string,
  read_more: PropTypes.string,
  quote_1: PropTypes.string,
  quote_author_1: PropTypes.string,
  quote_author_description_1: PropTypes.string,
  fact_1: PropTypes.string,
  paragraph_6: PropTypes.string,
  image_1: PropTypes.string,
  image_2: PropTypes.string,
  image_3: PropTypes.string,
  animation_image: PropTypes.string,
  medium_link: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

export default CoreStoryPageTemplate;
